import 'slick-carousel';
import wNumb from 'wnumb/wnumb.js';
import noUiSlider from 'nouislider/distribute/nouislider.js';
import "magnific-popup/dist/jquery.magnific-popup.js";
import 'inputmask/dist/inputmask/jquery.inputmask.js';
import 'page-scroll-to-id/jquery.malihu.PageScroll2id.js';
import 'jquery-validation';
import 'simplebar';

$(document).ready(function(){
	// preloader start
	setTimeout(function(){
		$('.preloader').fadeOut();
	}, 500);
	// preloader end

	$(".nav a").mPageScroll2id();

	$(".dropdown-menu a").mPageScroll2id();

	$('.dropdown-menu a').click(function(){
		$('.btn-menu').removeClass('open');
		$('.dropdown-menu').slideToggle().removeClass('active');
	});

	$('.scroll-down').mPageScroll2id();

	// mobile menu toogle start
	$('.btn-menu').click(function(){
		$(this).toggleClass('open');
		$('.dropdown-menu').slideToggle().toggleClass('active');
	});
	// mobile menu toogle end

	// floors slider start 
	$('.floors-slider').slick({
		slidesToShow: 1,
		arrows: false,
		swipe: false,
		drag: false,
		fade: true,
		speed: 200
	});
	// floors slider end

	// toggle typical floor start
	$('.entrance').click(function() {        
		$(this).addClass('active').siblings().removeClass('active');
		const slideno = $(this).data('slide');
		$('.floors-slider').slick('slickGoTo', slideno - 1);
	});
	// toggle typical floor end
	
	// show/hide infastructure block start
	$('.infrastructure-block__close-btn').click(function(){
		$(this).toggleClass('close');
		$('.infrastructure-block__body').slideToggle();
	});
	// show/hide infastructure block end

	// gallery progress start 
	$('.gallery').slick({
		slidesToShow: 1,
		arrows: false,
		swipe: false,
		drag: false,
		fade: true,
		speed: 200,
		asNavFor: '.month-slider'
	});
	// gallery progress end

	// room slider start
	$('.room-slider').slick({
		slidesToShow: 1,
		arrows: false,
		fade: true,
		asNavFor: '.room-preview-slider',
	});

	$('.room-preview-slider').slick({
		slidesToShow: 3,
		arrows: false,
		vertical: true,
		asNavFor: '.room-slider',
		focusOnSelect: true
	});
	// room slider end

	// progress sliders start
	$('.progress-slider').slick({
		slidesToShow: 1,
		arrows: false,
		fade: true,
	});

	$('.progress-slider').each(function(){

		const parent = $(this);
		const leftArr = $(this).find('.progress-slider__nav-left');
		const rightArr = $(this).find('.progress-slider__nav-right');

		leftArr.click(function(){
			parent.slick('slickPrev');
		})
		  
		rightArr.click(function(){
			parent.slick('slickNext');
		})

	});
	// progress sliders start

	// month slider start
	$('.month-slider').slick({
		slidesToShow: 3,
		arrows: true,
		centerMode: true,
		prevArrow: '<div class="month-slider__arrow month-slider__arrow-left"><img src="static/img/prev.svg"></div>',
		nextArrow: '<div class="month-slider__arrow month-slider__arrow-right"><img src="static/img/next.svg"></div>',
		asNavFor: '.gallery',
		focusOnSelect: true,
		responsive: [
			{
			  breakpoint: 992,
			  settings: {
				centerPadding: '0px',
			  }
			},
			{
				breakpoint: 556,
				settings: {
				  centerMode: false,
				  slidesToShow: 1
				}
			  }
		  ]
	});

	$(".month-slider").on("beforeChange",  function (e){
		$('.progress-slider .slick-slide:first-child').addClass('slick-active');
		$('.progress-slider').slick('slickSetOption', 'slidesToShow', 1);
		$('.progress-slider').slick('setPosition');
	})
	// month slider end

	// docs slider start
	$('.docs-carousel').slick({
		slidesToShow: 3,
		arrows: true,
		nextArrow: '<div class=""><img src="static/img/prev.svg"></div>',
		prevArrow: '<div class=""><img src="static/img/next.svg"></div>',
		responsive: [
			{
			  breakpoint: 992,
			  settings: {
				slidesToShow: 2
			  }
			},
			{
				breakpoint: 556,
				settings: {
				  slidesToShow: 1
				}
			  }
		  ]
	});
	// docs slider end

	// developer slider start
	$('.developer-slider').slick({
		slidesToShow: 1,
		arrows: false,
		fade: true,
	});

	$('.developer-slider').each(function(){

		const parent = $(this);
		const leftArr = $(this).find('.developer-slider__nav-left');
		const rightArr = $(this).find('.developer-slider__nav-right');

		leftArr.click(function(){
			parent.slick('slickPrev');
		})
		  
		rightArr.click(function(){
			parent.slick('slickNext');
		})

	});
	// developer slider start

	// popup form start
	const keys = {37: 1, 38: 1, 39: 1, 40: 1};
	const preventDefault = (e) => {
		e = e || window.event;
		if (e.preventDefault)
			e.preventDefault();
		e.returnValue = false;  
	}
	const preventDefaultForScrollKeys = (e) => {
		if (keys[e.keyCode]) {
			preventDefault(e);
			return false;
		}
	}

	const disableScroll = () => {
		if (window.addEventListener) // older FF
			window.addEventListener('DOMMouseScroll', preventDefault, false);
		window.onwheel = preventDefault; // modern standard
		window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
		window.ontouchmove  = preventDefault; // mobile
		document.onkeydown  = preventDefaultForScrollKeys;
	}
	const enableScroll = () => {
		if (window.removeEventListener)
			window.removeEventListener('DOMMouseScroll', preventDefault, false);
		window.onmousewheel = document.onmousewheel = null; 
		window.onwheel = null; 
		window.ontouchmove = null;  
		document.onkeydown = null;  
	}

	$('.popup-link').magnificPopup({
		type: 'inline',
		fixedContentPos: false,
		fixedBgPos: false,
		removalDelay: 0,
		mainClass: 'mfp-fade',
		callbacks: {
			open: function() {
				$('body, html').addClass('disable-scroll');
				disableScroll();
			},
			close: function() {
				$('.popup').removeClass('active');
				$('body, html').removeClass('disable-scroll');
				enableScroll();
				$('#flat-field').remove();
			},
		}
	});
	// popup form end

	// gallery start
	$('.docs-carousel').magnificPopup({
		delegate: 'a',
		type: 'image',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
		},
	});
	// gallery end

	$('.order-btn').click(function(){
		const data = $(this).data('flat');
		console.log(data);
		$('#form .form__body').append('<input type="hidden" id="flat-field" name="Flat" />')
		$('#flat-field').val(data);
	});

	// phone mask start
	$('.phone-mask').inputmask({mask: "+38 (999) 99 - 99 - 999", showMaskOnHover: false}); 
	// phone mask end

	$.validator.addMethod("phoneLength", function (value, element) {
		return value.replace(/\D+/g, '').length === 12;
	}, "Некорректный номер!");

	$("#form").validate({
		rules: {
			Phone: {
				phoneLength: true,
				required: true
		   }
		},
		messages: {
			Phone: {
			   phoneLength: "",
			   required: ""
		   }
		 },
		 submitHandler: function (form) {
			$.ajax({
				type: "POST",
				   url: "/mail.php",
				data: $(form).serialize(),
				success: function () {
				  $('.form__back').addClass('active');
				  gtag('event','send',{'event_category':'form'});
				   setTimeout(function() {
					   $(form).trigger("reset");
					   $('#form').magnificPopup('close');	
				   }, 2000);
				   setTimeout(function() {
					   $('.form__back').removeClass('active');
				   }, 2500);
				}
			});
			return false; // required to block normal submit since you used ajax
		}
	});

	// init gallery sliders start

	$('.btn-gallery').click(function(e){
		e.preventDefault();
		$('.popup-slider').addClass('open');
		disableScroll();
	});

	$('.popup-slider__close-btn').click(function(){
		$('.popup-slider').removeClass('open');
		enableScroll();
	})

	$('.gallery-big-images').slick({
		asNavFor: '.gallery-preview-images',
		arrows: true,
		dots: false,
		fade: true,
		prevArrow: '<div class="gallery-big-images__arrow gallery-big-images__arrow--prev"><img src="/static/img/prev.svg" alt=""></div>',
		nextArrow: '<div class="gallery-big-images__arrow gallery-big-images__arrow--next"><img src="/static/img/next.svg" alt=""></div>',
	});

	$('.gallery-preview-images').slick({
		slidesToShow: 5,
		arrows: false,
		dots: false,
		asNavFor: '.gallery-big-images',
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 568,
				settings: {
					slidesToShow: 2,
				}
			},
		]
	});

	// init gallery sliders end

	// noUislider start 
	

	const floorSlider = document.getElementById('floor-slider');
	
	noUiSlider.create(floorSlider, {
		start: [1, 14],
		connect: true,
		animate: true,
		tooltips: true,
		animationDuration: 300,
		step: 1,
		range: {
			'min': 1,
			'max': 14
		},
		format: wNumb({
            decimals: 0,
            thousand: ' ',
            postfix: ' '
        })
	});

	floorSlider.noUiSlider.on('update', function(values, handle) {
		const firstHandle = handle[values];
	})

	const areaSlider = document.getElementById('area-slider');

	noUiSlider.create(areaSlider, {
		start: [30, 140],
		connect: true,
		step: 5,
		animate: true,
		tooltips: true,
    	animationDuration: 300,
		range: {
			'min': 30,
			'max': 140
		},
		format: wNumb({
            decimals: 0,
            thousand: ' ',
            postfix: ' '
        })
	});

	function getNoUiSliderVal (slider) {
		return slider.noUiSlider.get().map(Number);
	}


	function getFilterValues() {
		return {
			roomAmounts: [
				$('#num-1').is(':checked'),
				$('#num-2').is(':checked'),
				$('#num-3').is(':checked'),
			],
			area: getNoUiSliderVal(areaSlider),
			floor: getNoUiSliderVal(floorSlider),
		};
	}
	
	function isFlatMatches(flat, filter) {
		const {roomAmounts, area, floor} = filter;
		if (flat.area < area[0] || flat.area > area[1]) {
			console.log('Area mismatch')
			return false;
		}

		if (flat.floor < floor[0] || flat.floor > floor[1]) {
			console.log('Floor mismatch')
			return false;
		}
		for (const [index, enabled] of roomAmounts.entries()) {
			if (!enabled && (index+1 == flat.rooms)) {
				console.log('Rooms mismatch', index+1)
				return false;
			}
		}
		return true;
	}

	$('.filter-btn').click(function(e) {
		e.preventDefault();
		const filterValues = getFilterValues();
		console.log("FILTER: ", filterValues);
		const list = $('.flat-card');
		let haveAny = false;
		for (const flatEl of list) {
			const flatJq = $(flatEl);
			const flatIndex = flatJq.data('flat-index');
			const flatData = allFlats[flatIndex];
			console.log("Flat data: ", flatData);
			if (isFlatMatches(flatData, filterValues)) {
				haveAny = true;
				flatJq.removeClass('d-none');
			} else {
				flatJq.addClass('d-none');
			}
		}
		if (!haveAny) {
			$('#no-matches').removeClass('d-none')
		} else {
			$('#no-matches').addClass('d-none')
		}
	});

	$('.checkbox').on('click',function () {
		const input = $(this).find('input');
        if (input.is(':checked')) {
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
	});

});